.fc-header-toolbar {
  margin-top: 1rem;
  margin-bottom: 2rem !important;
}
.fc-toolbar-chunk .fc-toolbar-title {
  font-family: 'Quicksand';
  font-size: 1.25rem;
  position: relative;
  left: 175px;
}
.fc-button {
  font-size: 14px;
}
.fc-button-group {
  border-radius: 10px !important;
}
.fc-button-primary {
  color: white !important;
  background-color: #448aff !important;
  border: none !important;
  text-transform: capitalize !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
  background-color: #2f60b2 !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
  background-color: #2f60b2 !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event {
  font-size: 14px;
  margin-bottom: 3px;
  font-weight: 500;
}

.fc-h-event {
  border-radius: 4px;
}
